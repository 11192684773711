<template>
  <v-card class="pa-8">
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h3>เพิ่มโปรโมชัน</h3>
      <v-row>
        <!-- <v-col cols="6"
          ><span>รหัสโปรโมชัน*</span>
          <v-select
            v-model="category"
            :items="itemsCategory"
            item-text="category"
            item-value="id"
            label="รหัสโปรโมชัน"
            outlined
            dense
            :rules="rules.required"
          ></v-select
        ></v-col>
        <v-col cols="6"
          ><span>เลือกประเภทโปรโมชัน*</span>
          <v-select
            v-model="type"
            :items="['ซื้อ x แถม x','ซื้อ x แถม y',]"
            
            label="เลือกประเภทโปรโมชัน"
            outlined
            dense
            :rules="rules.required"
          ></v-select
        ></v-col> -->

        <v-col cols="3"
          ><p class="pt-2">รหัสโปรโมชัน*</p>
          <v-text-field
            v-model="sku"
            label="รหัสโปรโมชัน"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>

        <v-col cols="3"
          ><p class="pt-2">ชื่อโปรโมชัน*</p>
          <v-text-field
            v-model="productName"
            label="ชื่อโปรโมชัน"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>

        <v-col cols="6"
          ><p class="pt-2">รายละเอียด*</p>
          <v-text-field
            v-model="description"
            label="รายละเอียด"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>

        <v-col cols="3"
          ><p class="pt-2">รหัสโปรโมชัน*</p>
          <v-text-field
            v-model="unit"
            label="EXAMPLE001"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>

        <v-col cols="3"
          ><p class="pt-2">จำนวน*</p>
          <v-text-field
            v-model="price"
            label="จำนวน"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="6"></v-col>
        <v-col cols="3"
          ><p class="pt-2">แบบยกโหล</p>
          <v-text-field
            v-model="unitDozen"
            label="12 ชิ้น"
            outlined
            dense
          ></v-text-field>
        </v-col> -->

        <v-col cols="3"
          ><p class="pt-2">ขั้นต่ำ</p>
          <v-text-field
            v-model="priceDozen"
            label="ขั้นต่ำ"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="3"
          ><p class="pt-2">ราคายกลัง/กล่อง</p>
          <v-text-field
            v-model="pricePack"
            label="ราคายกลัง/กล่อง"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3"
          ><p class="pt-2">ยกลัง/กล่องละ(ชิ้น)</p>
          <v-text-field
            v-model="unitPack"
            label="ยกลัง/กล่องละ"
            outlined
            dense
          ></v-text-field>
        </v-col> -->

        <!-- <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="11" class="mt-5 ml-10" id="step-3">
                <h2>รูปสินค้าหลัก*</h2>
                <v-img :src="showImage" width="200px"></v-img>
                <v-text-field
                  v-model="picture_1"
                  @click="changePic1()"
                  :placeholder="imageName1"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/*"
                  @change="showPicture1($event)"
                  style="display: none"
              /></v-col>
            </v-row>
          </v-card>
        </v-col> -->

        <!-- <v-col cols="12" class="mt-5">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="11" class="mt-5 ml-10" id="step-3">
                <h2>รูปภาพสินค้าเพิ่มเติม</h2>
              </v-col>
              <v-col cols="12" class="mb-5"><v-divider></v-divider></v-col>
              <v-col cols="12" md="12" class="pl-3 mb-5" @click="onPickFile()">
                <v-row no-gutters align="center" justify="center">
                  <v-file-input
                    v-model="DataImage"
                    :items="DataImage"
                    accept="image/jpeg, image/jpg, image/png"
                    @change="UploadImage()"
                    id="file_input"
                    multiple
                    :clearable="false"
                    style="display: none"
                  ></v-file-input>
                  <v-img
                    max-width="50"
                    src="@/assets/upload.png"
                    class="mr-3"
                  ></v-img>
                  <span>เลือกรูปภาพ</span>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="12"
                v-if="product_image.length !== 0"
                class="mb-5"
              >
                <draggable
                  v-model="product_image"
                  :move="onMove"
                  @start="drag = true"
                  @end="drag = false"
                  class="pl-5 pr-5 row fill-height align-center sortable-list"
                >
                  <v-col
                    v-for="(item, index) in product_image"
                    :key="index"
                    cols="3"
                    md="3"
                  >
                    <v-card dense light class="pa-1">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage(index, item)"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>
                      
                      <v-img :src="item.url" aspect-ratio="1.8" contain></v-img>
                      <v-card-text class="text-md-center">
                      
                      </v-card-text>
                    </v-card>
                  </v-col>
                </draggable>
              </v-col>
            </v-row>
          </v-card>
        </v-col> -->
      </v-row>

      <v-row class="ma-5">
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()" color="primary">บันทึก</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import draggable from "vuedraggable";
import { Decode } from "@/services";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      sku: "",
      productName: "",
      description: "",
      unit: "",
      price: "",
      pricePack: "",
      unitPack: "",
      priceDozen: "",
      unitDozen: 12,
      img: [],
      DataImage: [],
      product_image: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    UploadImage() {
      for (let i = 0; i < this.DataImage.length; i++) {
        const element = this.DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          var resultReader = reader.result;
          var url = URL.createObjectURL(element);
          this.product_image.push({
            image_data: resultReader,
            url: url,
            name: this.DataImage[i].name,
            id: "-1",
          });
        };
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.product_image.splice(index, 1);
    },
    cancel() {
      this.$router.push("ManagePromotion");
    },
    async submit() {
      if (this.pricePack == "") {
        this.pricePack = 0;
      }
      if (this.unitPack == "") {
        this.unitPack = 0;
      }
      if (this.priceDozen == "") {
        this.priceDozen = 0;
      }
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(Decode.decode(localStorage.getItem("user")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const productData = {
          categoryId: this.category,
          typeId: this.type,
          sku: this.sku,
          productName: this.productName,
          description: this.description,
          unit: this.unit,
          price: this.price,
          pricePack: this.pricePack,
          unitPack: this.unitPack,
          priceDozen: this.priceDozen,
          unitDozen: this.unitDozen,
          imgUrl: this.pic1,
          imgUrlDesc: this.product_image,
        };
        console.log("data", productData.unitPack);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/products`,
          productData,
          auth
        );
        console.log("response product", response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `เพิ่มสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageProduct");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `เพิ่มสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
  async created() {
    const cateDropdown = await this.axios.get(
      `${process.env.VUE_APP_API}/categories`
    );
    const typeDropdown = await this.axios.get(
      `${process.env.VUE_APP_API}/types`
    );
    this.itemsCategory = cateDropdown.data.data;
    this.itemsType = typeDropdown.data.data;
    console.log(this.itemsCategory, this.itemsType);
  },
};
</script>